import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Dashboard } from "./components/dashboard";
import { Clickable } from "./components/clickable";
import { About } from "./components/about";
import { Purpose } from "./components/purpose";
import { Gallery } from "./components/gallery";
import {Annual} from "./components/annual";
import { Partners } from "./components/partners";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Dashboard data={landingPageData.Dashboard} />
      <Clickable data={landingPageData.Clickable} />
      <About data={landingPageData.About} />
      <Purpose data={landingPageData.Purpose} />
      <Gallery data={landingPageData.Gallery} />
      <Annual data={landingPageData.Annual} />
      <Team data={landingPageData.Team} />
      <Partners data={landingPageData.Partners} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
