import React from "react";
import { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import axios from 'axios'; // Import Axios

export const Dashboard = (props) => {
  const [csvData, setCsvData] = useState([]);
  const [baskets, setBaskets] = useState(0); // Define a new state variable for baskets
  const [soccer, setSoccer] = useState(0);
  const [breakfast, setBreakfast] = useState(0);
  const [codingCamp, setCodingCamp] = useState(0);

    useEffect(() => {
        fetchCSVData();    // Fetch the CSV data when the component mounts
    }, []); // The empty array ensures that this effect runs only once, like componentDidMount

    const fetchCSVData = () => {
    const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT2Z9oHLCjXDLIbv8nr1pUVXiDmGtytDH0GKA7OqUnGCmXIcvRbD6JoqU_dJ7XIxpNJMRRdyPj5NgSK/pub?gid=1485177664&single=true&output=csv'; // Replace with your Google Sheets CSV file URL

        axios.get(csvUrl)    // Use Axios to fetch the CSV data
            .then((response) => {
                const parsedCsvData = parseCSV(response.data);        // Parse the CSV data into an array of objects
                setCsvData(parsedCsvData);        // Set the fetched data in the component's state
                console.log(parsedCsvData);        // Now you can work with 'csvData' in your component's state.
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
            });
    }

    function parseCSV(csvText) {
        const rows = csvText.split(/\r?\n/);        // Use a regular expression to split the CSV text into rows while handling '\r'
        const headers = rows[0].split(',');        // Extract headers (assumes the first row is the header row)
        const data = [];        // Initialize an array to store the parsed data
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');          // Use the regular expression to split the row while handling '\r'
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = rowData[j];
            }
            data.push(rowObject);
        }
        // Assuming data is populated, get the first value of the first header
const firstHeader = headers[0];
const firstValue = data.length > 0 ? data[0][firstHeader] : null;
setBaskets(firstValue);
setSoccer(data[0][headers[1]]);
setBreakfast(data[0][headers[2]]);
setCodingCamp(data[0][headers[3]]);


        return data;
    }
  return (
    <div id="dashboard" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Dashboard</h2>
        </div>
        <div className="row">
        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <i class="fa fa-group" aria-hidden="true"></i>
        <h3><CountUp 
          
        start={0}
        end={baskets}
        duration={8}
        delay={1}
        separator=" "
        /> {"+"}</h3>
        <p>{"Food baskets delivered to local families"}</p>
        </div>

        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <i class="fa fa-futbol-o" aria-hidden="true"></i>
        <h3><CountUp 
          
        start={0}
        end={soccer}
        duration={8}
        delay={1}
        separator=" "
        /> {"+"}</h3>
        <p>{"At-risk youth playing soccer!"}</p>
        </div>
        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <i class="fa fa-laptop" aria-hidden="true"></i>
        <h3><CountUp 
          
        start={0}
        end={codingCamp}
        duration={8}
        delay={1}
        separator=" "
        /> {"+"}</h3>
        <p>{"KhayrTronix Coding Camp graduates!"}</p>
        </div>
        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <i class="fa fa-cutlery" aria-hidden="true"></i>
        <h3><CountUp 
          
        start={0}
        end={breakfast}
        duration={8}s
        delay={1}
        separator=" "
        /> {"+"}</h3>
        <p>{"Breakfasts served to local seniors!"}</p>
        </div>

        </div>
      </div>
    </div>
  );
};
