import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";


const initialState = {
  name: " ",
  email: " ",
  message: " ",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("service_u5wyr9f", "template_w647z0j", e.target, "hYhHycZetEqDelfxC")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          // set placeholder for email message and name to empty
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("message").value = "";
          alert("Email sent successfully!");
        },
        (error) => {
          console.log(error.text);
        }
      );
      
  };
  const today = new Date();
  const year = today.getFullYear();
  return (
    
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <iframe src="https://us1.list-manage.com/contact-form?u=bed5e4f18393a1b94c60c1959&form_id=5b9afa9406272711ac8aad2a87b205c7" width="100%" height="100%"></iframe>





              <div className="contact-item">
              <ul>  
                <li>
                  
                </li>
              </ul>
          </div>
            </div>

          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
            <h2>Contact Info</h2>
              
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
              <p>
                {props.data ? props.data.phone2 : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-fax"></i> Fax
                </span>{" "}
                {props.data ? props.data.fax : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
                
              </p>
            </div>
            <div className="contact-item">
              <ul>  
                <li>
                  <a href={props.data ? props.data.donate : "/"}>
                  <button type="submit" className="btn btn-custom2 btn-lg"> Donate
                  </button>
                  </a>
                </li>
              </ul>
          </div>
          <div className="contact-item">
              <ul>  
                <li>
                  <a href={props.data ? props.data.volunteer : "/"}>
                  <button type="submit" className="btn btn-custom2 btn-lg"> Volunteer
                  </button>
                  </a>
                </li>
              </ul>
          </div>
          <div className="contact-item">
              <ul>  
                <li>
                  <a href={props.data ? props.data.newsletter : "/"}>
                  <button type="submit" className="btn btn-custom2 btn-lg"> Sign-Up for Newsletter
                  </button>
                  </a>
                </li>
              </ul>
          </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkedin : "/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {year} Khayr Project Canada. CRA Charitable Registration #: 748751203RR0001
          </p>
        </div>
      </div>
    </div>
    
  );
};
