import React from 'react'
import Marquee from 'react-fast-marquee'

export const Partners = () => {
  return (
    <>
      <div id="partners">
        <section className="flex h-screen justify-center items-center w-screen">
        <div className="col-md-8 col-md-offset-2 section-title text-center">
        <h2>Partners & Affiliates</h2>
        </div>
          <div className="h-[200px] shadow-lg shadow-slate-400 rounded-2xl w-1/3 mx-auto">
            <Marquee 
            gradient={false} 
            pauseOnHover={false} 
            speed={100} // Adjust the speed value (in pixels per second) as needed
            className="border-t rounded-2xl border-b py-3 overflow-hidden grid">
              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/Hamilton.png" className="image" alt="Hamilton" />
              </div>

              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/Hamilton-HS.png" className="image" alt="Hamilton-HS" />
              </div>

              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/IRC.png" className="image" alt="IRC" />
              </div>

              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/NZF.png" className="image" alt="NZF" />
              </div>
              
              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/Canada.png" className="image" alt="NZF" />
              </div>

              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/Hamilton.png" className="image" alt="Hamilton" />
              </div>

              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/Hamilton-HS.png" className="image" alt="Hamilton-HS" />
              </div>

              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/IRC.png" className="image" alt="IRC" />
              </div>

              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/NZF.png" className="image" alt="NZF" />
              </div>

              <div className="image-container flex flex-col justify-center items-center h-[350px] mx-5">
                <img src="img/logos/Canada.png" className="image" alt="NZF" />
              </div>



            </Marquee>
          </div>
        </section>
      </div>  

      <style jsx>{`
        .image {
          height: 220px;
          width: auto;
          max-width: 100%;
        }

        .image-container {
          padding: 0 30px; /* Adjust the value as needed for the desired spacing */
        }
      `}</style>
    </>
  )
};
