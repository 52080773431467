import React from "react";
import { useEffect, useState } from 'react'

export const Clickable = (props) => {
  return (
    <div id="clickable" className="text-center">
      <div className="container">
        <div className="row">
        

        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <a href="https://fs2.formsite.com/drkhanclinic/ltafa7g9ux/index"> 
        <i class="fa fa-bullhorn hover" aria-hidden="true"></i>
        </a>
        <h3>{"KhayrYouth Soccer Registration!"}</h3>
        <p>{"Click to register in the KhayrYouth Soccer Cohort 5 starting September 2024"}</p>
        </div>
        
        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <a href="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FToronto&bgcolor=%2333B679&title=Khayr%20Project%20Canada%20Event%20Calendar&src=ZGI4OGE4YmRjOTZkNjBjMDBkYzE5NWFjY2UwMDc1ZTY4OTE3OTI2MjRlMDE4MjMwM2ZiY2I2MjVhNGE2NzAwY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%238E24AA"> 
        <i class="fa fa-calendar hover" aria-hidden="true"></i>
        </a>        
        <h3>{"Community Calendar"}</h3>
        <p>{"Click to view important dates and events happening within our community."}</p>
        </div> 
        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <a href="https://khayrproject.us1.list-manage.com/subscribe?u=bed5e4f18393a1b94c60c1959&id=3621ba0ea7"> 
        <i class="fa fa-newspaper-o hover" aria-hidden="true"></i>
        </a>        
        <h3>{"Sign up to our Newsletter"}</h3>
        <p>{"Click to register to stay up to date on our new and returning initiatives."}</p>
        </div> 
        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <a href="https://www.zeffy.com/en-CA/donation-form/8a345467-3a8c-403f-babc-b24b48fe7d76"> 
        <i class="fa fa-heart hover" aria-hidden="true"></i>
        </a>        
        <h3>{"Donate to Our Program"}</h3>
        <p>{"Click to support our initiatives and help us make a positive impact by donating to our program."}</p>
        </div>
        

      {/* for later 

      <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <i class="fa fa-futbol-o" aria-hidden="true"></i>
        <h3><CountUp 
          
        start={0}
        end={120}
        duration={8}
        delay={1}
        separator=" "
        /> {"+"}</h3>
        <p>{"Youth Served in Soccer!"}</p>
        </div>
        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <i class="fa fa-laptop" aria-hidden="true"></i>
        <h3><CountUp 
          
        start={0}
        end={25}
        duration={8}
        delay={1}
        separator=" "
        /> {"+"}</h3>
        <p>{"KhayrTronix Youth Coders!"}</p>
        </div>
        <div key="Upcoming Event" className="col-xs-6 col-md-3">
        <i class="fa fa-pagelines" aria-hidden="true"></i>
        <h3><CountUp 
          
        start={0}
        end={60}
        duration={8}
        delay={1}
        separator=" "
        /> {"+"}</h3>
        <p>{"Seniors Served!"}</p>
        </div>
      */}


       </div>
      </div>
    </div>
  );
};
