import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>




          <a className="navbar-brand page-scroll" href="#page-top" style={{ display: 'flex', alignItems: 'center' }}>
            <img src="img/green_nobg.png" alt="GreenLogo" style={{ height: '45px', marginRight: '10px' }} />
            Khayr Project Canada
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#dashboard" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#purpose" className="page-scroll">
                Purpose
              </a>
            </li>
            <li>
              <a href="#projects" className="page-scroll">
                Projects
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
            <li>
              <a href="#partners" className="page-scroll">
                Partners
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            
          </ul>
          
        </div>
        
      </div>
      <a href="https://www.zeffy.com/en-CA/donation-form/8a345467-3a8c-403f-babc-b24b48fe7d76" className="btn btn-custom2 btn-lg">
           Donate
          </a>
    </nav>
  );
};
